/* ADAPTED FROM JQUERY SOURCE */
function param(a)
{
  var prefix,
      params = [],
      add    = function(key, value) {
        params[params.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value == null ? "" : value);
      };

  for (prefix in a) {
    buildParams(prefix, a[prefix], add);
  }

  // Return the resulting serialization
  return params.join("&");
}

function buildParams(mainKey, mainValue, add)
{
  var name;
  var length = mainValue.length;
  if (Array.isArray(mainValue)) {
    for (var index = 0; index < length; index++) {
      var value = mainKey[index];
      if (/\[]$/.test(mainKey)) {
        add(mainKey, value);
      }
      else {
        buildParams(mainKey + "[" + (typeof value === "object" && value != null ? index : "") + "]", value, add);
      }
    }
  }
  else if (typeof mainValue === "object") {
    for (name in mainValue) {
      buildParams(mainKey + "[" + name + "]", mainValue[name], add);
    }
  }
  else {
    add(mainKey, mainValue);
  }
}

/* END CODE ADAPTED FROM JQUERY SOURCE */

/*
* Created by Skeets 2017-12-13
* */

onmessage = function(e) {

  var obj = JSON.parse(e.data);

  obj.data._token = obj.csrf;

  var request = new XMLHttpRequest();
  request.open('POST', obj.url, true);
  request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      // Success
      var output;
      try {
        output = JSON.parse(request.responseText);
      }
      catch (e) {
        output = request.responseText;
      }
      postMessage(output);
    }
    else {
      // error
      console.log(request.responseText);
    }
  };

  request.onerror = function() {
    // connection error
  };

  request.send(param(obj.data));

};
